import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

export const qualityControlRoutes: RouteConfig[] = [
  {
    path: '/orders/:tabRoute?',
    name: routeNames.ORDERS,
    component: () => import('@/views/dashboard/Orders/Orders.vue'),
    meta: {
      authRequired: true,
      whiteBackground: true,
    },
    props: true,
  },
];
