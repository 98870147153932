<template>
  <div
    class="App"
    :class="classNames"
  >
    <Loading
      v-if="isLoadingBlocking || isLoadingNonBlocking"
      active
      opaque
    />
    <NavAppWrapper
      v-else
      class="App__nav-bar"
      compactNavBar
      displayCustomSideMenu
      smallLogo
      :logInRoute="loginRoute()"
      :navItemsForLoggedInUser="navItemsForLoggedInUser"
    >
      <template #sideMenu>
        <SessionManager />
      </template>
      <template #app>
        <TransitionDownUp>
          <router-view class="App__content" />
        </TransitionDownUp>
      </template>
    </NavAppWrapper>
  </div>
</template>

<script lang="ts">
import { EncryptedUser, Role } from '@caresend/types';
import {
  Loading,
  NavAppWrapper,
  NavItem,
  getRoute,
  getStore,
} from '@caresend/ui-components';
import { computed, defineComponent, watch } from 'vue';

import TransitionDownUp from '@/components/layout/transitions/TransitionDownUp.vue';
import {
  databaseScriptsRoute,
  filesRoute,
  inventoryRoute,
  locationsRoute,
  loginRoute,
  nurseDispatchRoute,
  officesRoute,
  ordersRoute,
  processingOrdersRoute,
  recruitingMapRoute,
  rescheduleVisitRoute,
  sandboxRoute,
  shiftsRoute,
  taskTypesListRoute,
  tasksListRoute,
  testsRoute,
  usersRoute,
  waypointSupplyListRoute,
} from '@/router/locations';
import { routeNames } from '@/router/model';
import { useSessionStore } from '@/store/modules/itinerary/sessionStore';
import SessionManager from '@/views/dashboard/SessionManager.vue';

type NavItemWithRoles = NavItem & {
  /**
   * Currently only supported at root level. All children will be allowed if
   * parent is allowed.
   */
  allowedRoles?: Role[];
}

export default defineComponent({
  name: 'App',
  components: {
    Loading,
    NavAppWrapper,
    SessionManager,
    TransitionDownUp,
  },
  setup() {
    const store = getStore();
    const route = getRoute();

    /**
     * On sign-out, close all active sessions
     * TODO: Remove this once session store is migrated to ui-c
     */
    watch(() => store.state.auth.user, (newVal, oldVal) => {
      if (oldVal && !newVal) {
        useSessionStore().closeAllSessions();
      }
    });

    const isWhiteBackground = computed<boolean>(() => {
      const whiteBgMeta = route.value.meta?.whiteBackground;
      const isWhiteBg = typeof whiteBgMeta === 'function'
        ? whiteBgMeta({ store }).value
        : !!whiteBgMeta;

      return isWhiteBg;
    });

    const classNames = computed<string[]>(() => {
      const names = [];
      if (isWhiteBackground.value) {
        names.push('App--white-bg');
      }
      return names;
    });

    /**
     * Loading conditions that prevent the router-view from rendering.
     */
    const isLoadingBlocking = computed(() =>
      route.value.name !== routeNames.DEV_ADVANCED_SETTINGS
      && !store.state.variables.variables,
    );

    /**
     * Loading conditions that allow the router-view to render.
     */
    const isLoadingNonBlocking = computed(() =>
      store.state.auth.authLoading
      || store.state.app.showRouteLoading,
    );

    const user = computed<EncryptedUser | undefined>(() => store.state.auth.user);

    const localNavItemConfig: NavItemWithRoles[] = [
      {
        label: 'Users',
        to: usersRoute(),
        allowedRoles: [Role.SUPERADMIN, Role.RECRUITER],
      },
      {
        label: 'Inventory',
        to: inventoryRoute(),
        allowedRoles: [Role.SUPERADMIN],
      },
      {
        label: 'Logistics',
        allowedRoles: [Role.SUPERADMIN],
        children: [
          {
            label: 'Dispatch',
            to: nurseDispatchRoute(),
          },
          {
            label: 'Shifts',
            to: shiftsRoute(),
          },
          {
            label: 'Reschedule Visits',
            to: rescheduleVisitRoute(),
          },
          {
            label: 'Supply Transfers',
            to: waypointSupplyListRoute(),
          },
          {
            label: 'Process Orders',
            to: processingOrdersRoute(),
          },
          {
            label: 'Orders',
            to: ordersRoute(),
          },
          {
            label: 'Files',
            to: filesRoute(),
          },
        ],
      },
      {
        label: 'Variables',
        allowedRoles: [Role.SUPERADMIN],
        children: [
          {
            label: 'Offices',
            to: officesRoute(),
          },
          {
            label: 'Locations',
            to: locationsRoute(),
          },
          {
            label: 'Tests',
            to: testsRoute(),
          },
          {
            label: 'Task Types',
            to: taskTypesListRoute(),
          },
          {
            label: 'Tasks',
            to: tasksListRoute(),
          },
        ],
      },
      {
        label: 'Database',
        allowedRoles: [Role.SUPERADMIN],
        children: [
          {
            label: 'Scripts',
            to: databaseScriptsRoute(),
          },
          {
            label: 'Sandbox',
            to: sandboxRoute(),
          },
        ],
      },
      {
        label: 'Reporting',
        allowedRoles: [Role.SUPERADMIN],
        children: [
          {
            label: 'Recruiting Map',
            to: recruitingMapRoute(),
          },
        ],
      },
    ];

    const navItemsForLoggedInUser = computed<NavItem[]>(() =>
      localNavItemConfig.filter((item) =>
        user.value?.role && (item.allowedRoles ?? []).includes(user.value.role),
      ),
    );

    return {
      classNames,
      isLoadingBlocking,
      isLoadingNonBlocking,
      loginRoute,
      navItemsForLoggedInUser,
    };
  },
});
</script>

<style lang="sass" scoped>
.App
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: $cs-bg-color
  min-height: 100%
  display: flex
  position: relative
  flex-direction: column
  /**
    TODO: Commit this change to to CenteredPage in ui-components once its been verified that
    style treatment is left unchanged. This is required for the new NavAppWrapper
    component to maintain rigid bounds for app content.
    */
  ::v-deep .CenteredPage
    align-items: start

.App--white-bg
  background-color: $white

.App__content
  flex-grow: 1
  flex: 1 1 auto
  position: relative
</style>
