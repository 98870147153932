/**
 * Diff two objects deeply, returning a list of all the differences.
 * TODO: Move to utils after itinerary migration to ui-components
 *
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @param path - The current path of the comparison.
 * @param acc - The accumulator for the differences.
 * @returns The differences between the two objects.
 */
export const diffObjectsDeep = (
  _prev: any,
  _next: any,
  path: string[] = [],
  acc: Record<string, any> = {},
): Record<string, any> => {
  const prev = _prev ?? {};
  const next = _next ?? {};
  const result: Record<string, any> = {};

  // Get all unique keys from both objects
  const keys = new Set([...Object.keys(prev), ...Object.keys(next)]);

  keys.forEach((key) => {
    const newPath = [...path, key];
    const value1 = prev[key];
    const value2 = next[key];

    if (
      typeof value1 === 'object'
      && value1 !== null
      && typeof value2 === 'object'
      && value2 !== null
    ) {
      // Recursive diff for nested objects
      const nestedDiff = diffObjectsDeep(value1, value2, newPath, acc);
      if (Object.keys(nestedDiff).length > 0) {
        // Only add if there are differences
        result[newPath.join('/')] = nestedDiff;
      }
    } else if (value1 !== value2) {
      // Direct comparison for non-objects or nulls
      result[newPath.join('/')] = { next: value2, prev: value1 };
      acc[newPath.join('/')] = { next: value2, prev: value1 };
    }
  });

  if (path.length === 0) {
    return acc;
  }
  return result;
};
