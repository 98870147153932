import { ExtendedCustomModule, WaypointModule, initwaypointModule } from '@caresend/ui-components';

const extraWaypointGetters = {
};

const waypointModuleExtension = {
  getters: extraWaypointGetters,
};

export const waypointModule: ExtendedCustomModule<
  WaypointModule,
  typeof waypointModuleExtension
> = initwaypointModule(waypointModuleExtension);

export type ExtendedWaypointModule = typeof waypointModule;

export type ExtendedWaypointMutations = ExtendedWaypointModule['mutations'];
export type ExtendedWaypointActions = ExtendedWaypointModule['actions'];
export type ExtendedWaypointGetters = ExtendedWaypointModule['getters'];
