import { HeadingValues } from '@caresend/ui-components';
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { filesRoute } from '@/router/locations';
import { routeNames } from '@/router/model';
import { qualityControlRoutes } from '@/router/routes/qualityControl';

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: routeNames.HOME,
    component: () => import('@/views/dashboard/Home.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/users',
    name: routeNames.USERS,
    component: () => import('@/views/dashboard/Users/Users.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/processing-queue/:orderID?',
    name: routeNames.PROCESSING_QUEUE,
    component: () => import('@/views/dashboard/ProcessingQueue/ProcessingQueue.vue'),
    meta: {
      authRequired: true,
    },
    props: true,
  },
  {
    path: '/shifts',
    name: routeNames.SHIFTS,
    component: () => import('@/views/dashboard/Shifts/Shifts.vue'),
    meta: {
      authRequired: true,
      heading: ({ prevRoute, store }) => computed<HeadingValues>(() => ({
        title: 'Shifts',
        confirmText: 'New Shift',
        leftAlignTitle: true,
        backLocation: {
          name: prevRoute?.name ?? routeNames.USERS,
        },
        confirmEvent: () => {
          store.commit('shifts/SET_IS_ADD_SHIFT_MODAL_OPEN', true);
          store.commit('shifts/RESET_DATA');
        },
      })),
    },
  },
  {
    path: '/shift-detail/:shiftID',
    name: routeNames.SHIFT_DETAILS,
    component: () => import('@/views/dashboard/Shifts/ShiftDetailsPage/ShiftDetailsPage.vue'),
    props: true,
    meta: {
      authRequired: true,
      heading: ({ route, store }) => {
        const detailsTitle = computed<string>(() =>
          store.getters['shifts/getShiftDetailsTitle'](route.params.shiftID));

        return computed<HeadingValues>(() => ({
          backLocation: {
            name: routeNames.SHIFTS,
          },
          confirmEvent: () => {
            store.commit('shifts/SET_IS_ADD_SHIFT_MODAL_OPEN', true);
          },
          confirmText: 'Edit Shift',
          leftAlignTitle: true,
          title: detailsTitle.value,
        }));
      },
    },
  },
  {
    path: '/inventory/:view?',
    name: routeNames.INVENTORY,
    component: () => import('@/views/dashboard/Inventory/Inventory.vue'),
    props: true,
    meta: {
      authRequired: true,
      heading: ({ prevRoute }) => computed<HeadingValues>(() => ({
        title: 'Inventory Management',
        leftAlignTitle: true,
        backLocation: {
          name: prevRoute?.name ?? routeNames.USERS,
        },
      })),
    },
  },
  {
    path: '/nurses',
    name: routeNames.NURSES,
    component: () => import('@/views/dashboard/NurseNetwork/NurseNetwork.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/nurse-detail/:nurseID',
    name: routeNames.NURSE_DETAILS,
    component: () => import('@/views/dashboard/Recruiter/NurseDetail.vue'),
    props: true,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/dispatch',
    name: routeNames.NURSE_DISPATCH,
    component: () => import('@/views/dashboard/NurseDispatch/NurseDispatch.vue'),
    meta: {
      authRequired: true,
    },
    props: true,
  },
  {
    path: '/dispatch/:itineraryID?',
    name: routeNames.ITINERARY,
    component: () => import('@/views/dashboard/NurseDispatch/components/CreateItinerary/CreateItinerary.vue'),
    meta: {
      authRequired: true,
      minimizableNavBar: true,
    },
    props: true,
  },
  {
    path: '/supply-transfers/:draftWaypointID?',
    name: routeNames.WAYPOINT_SUPPLY_LIST,
    component: () => import('@/views/dashboard/WaypointSupplyList/WaypointSupplyList.vue'),
    meta: {
      authRequired: true,
    },
    props: true,
  },
  {
    path: '/reschedule/:draftWaypointID?',
    name: routeNames.RESCHEDULE_VISIT,
    component: () => import('@/views/dashboard/RescheduleVisit/RescheduleVisit.vue'),
    props: true,
    meta: {
      authRequired: true,
      heading: ({ prevRoute }) => computed(() => ({
        title: 'Reschedule a visit',
        leftAlignTitle: true,
        backLocation: {
          name: prevRoute?.name ?? routeNames.USERS,
        },
      })),
    },
  },
  {
    path: '/files',
    name: routeNames.FILES,
    component: () => import('@/views/dashboard/Files.vue'),
    props: true,
    meta: {
      authRequired: true,
      heading: ({ route }) => computed(() => {
        const confirmTo = route.query.dbPath
          ? filesRoute()
          : undefined;

        return {
          title: 'Files',
          leftAlignTitle: true,
          confirmText: 'New upload',
          confirmTo,
        };
      }),
    },
  },
  {
    path: '/database-scripts',
    name: routeNames.DATABASE_SCRIPTS,
    props: true,
    component: () => import('@/views/dashboard/DatabaseScripts/DatabaseScripts.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/sandbox/:view?',
    props: true,
    name: routeNames.SANDBOX,
    component: () => import('@/views/dashboard/Sandbox/Sandbox.vue'),
    meta: {
      authRequired: true,
      minimizableNavBar: true,
    },
  },
  {
    path: '/tests/:view?',
    name: routeNames.TESTS,
    props: true,
    component: () => import('@/views/dashboard/Tests/TestManager.vue'),
    meta: {
      authRequired: true,
      heading: ({ prevRoute, store }) => computed<HeadingValues>(() => ({
        title: 'Test Dashboard',
        confirmText: 'Add Test',
        leftAlignTitle: true,
        backLocation: {
          name: prevRoute?.name ?? routeNames.USERS,
        },
        confirmEvent: () => {
          store.commit('tests/SET_IS_TEST_MODAL_OPEN', true);
        },
      })),
    },
  },
  {
    path: '/locationGroupType/:id',
    name: routeNames.PLACE_GROUP_TYPE_PAGE,
    component: () => import('@/views/dashboard/Locations/PlaceGroupTypePage.vue'),
    meta: {
      authRequired: true,
    },
    props: true,
  },
  {
    path: '/locationGroup/:id',
    name: routeNames.PLACE_GROUP_PAGE,
    component: () => import('@/views/dashboard/Locations/PlaceGroupPage.vue'),
    meta: {
      authRequired: true,
    },
    props: true,
  },
  {
    path: '/locations',
    name: routeNames.LOCATIONS,
    component: () => import('@/views/dashboard/Locations/Locations.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/filters',
    name: routeNames.FILTERS,
    component: () => import('@/views/variables/RecruitmentFilters.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/task-types-list',
    name: routeNames.TASK_TYPES_LIST,
    component: () => import('@/views/variables/TaskTypesList.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/tasks-list',
    name: routeNames.TASKS_LIST,
    component: () => import('@/views/variables/TasksList.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/city',
    name: routeNames.EDIT_CITY,
    component: () => import('@/views/variables/EditCity.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: routeNames.LOGIN,
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: '/',
    props: true,
    component: () => import('@/views/dashboard/QualityControl/QA.vue'),
    meta: {
      authRequired: true,
    },
    children: qualityControlRoutes,
  },
  {
    path: '/',
    props: true,
    component: () =>
      import('@caresend/ui-components/lib/src/components/specific/ProcedureDetails/ProcedureDetails/ProcedurePage.vue'),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '/procedure/:procedureID/:view?',
        name: routeNames.PROCEDURE_PAGE,
        props: true,
        component: () =>
          import('@caresend/ui-components/lib/src/components/specific/ProcedureDetails/ProcedureDetails/ProcedureDetails.vue'),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: '/offices',
    name: routeNames.OFFICES,
    component: () => import('@/views/dashboard/CreateOffice.vue'),
    meta: {
      authRequired: true,
      heading: ({ prevRoute }) => computed(() => ({
        title: 'Offices',
        leftAlignTitle: true,
        backLocation: {
          name: prevRoute?.name ?? routeNames.USERS,
        },
      })),
    },
  },
  {
    path: '/recruiting-map',
    name: routeNames.RECRUITING_MAP,
    component: () => import('@/views/dashboard/RecruitingMap/RecruitingMap.vue'),
    meta: {
      authRequired: true,
    },
  },
];
