import {
  ByID,
  EncryptedUser,
  Itinerary,
  PlaceGroup,
  Procedure,
  ServiceRegion,
  Task,
  Waypoint,
  WithFriendlyStatus,
} from '@caresend/types';
import { generateHash, getStore, getValueOnce } from '@caresend/ui-components';
import {
  deduplicateArray,
  flattenArray,
  getServiceRegionFromLocation,
  isEncrypted,
  isNullish,
  nullishFilter,
} from '@caresend/utils';
import { RawLocation } from 'vue-router';

import { routeNames } from '@/router/model';
import { useItineraryStore } from '@/store/modules/itinerary';

export const getBookingByWaypointActionID = (
  waypointActionID: string,
) => {
  const store = getStore();
  const waypointAction = store.state.itineraries.waypointActions?.[
    waypointActionID
  ];
  const firstProcedureID = Object.keys(waypointAction?.procedures ?? {})[0];
  if (isNullish(firstProcedureID)) return;
  const procedure = store.state.procedures.procedures[firstProcedureID];
  if (isNullish(procedure)) return;
  return store.state.bookings.bookings[procedure.bookingID];
};

export const getPatientIDsByWaypointID = (
  waypointID: string,
): string[] | undefined => {
  const store = getStore();
  const waypoint = store.state.itineraries.waypoints?.[waypointID];
  if (isNullish(waypoint)) return;
  const patients = Object.values(
    waypoint.waypointActions ?? {},
  ).map((waypointAction) => {
    const booking = getBookingByWaypointActionID(waypointAction.id);
    if (!booking?.patientID) return;
    return booking.patientID;
  }).filter(nullishFilter);
  return patients;
};

const getItineraryByID = (itineraryID: string): Itinerary | undefined => {
  const store = getStore();
  const itineraryStore = useItineraryStore();
  const currentEditingItinerary = itineraryStore.itinerary.value as Itinerary;
  if (!isNullish(currentEditingItinerary) && currentEditingItinerary.id === itineraryID) return currentEditingItinerary;
  return store.state.itineraries.itineraries?.[itineraryID];
};

export const getPatientsByItineraryID = (
  itineraryID: string,
): EncryptedUser[] | 'loading' => {
  const store = getStore();
  const itinerary = getItineraryByID(itineraryID);
  const groupedPatients = Object.values(
    itinerary?.waypoints ?? {},
  ).map((waypointIDObj) =>
    getPatientIDsByWaypointID(waypointIDObj.id)).filter(nullishFilter);
  const patientIDs = deduplicateArray(flattenArray(groupedPatients).filter(nullishFilter));
  const someEncryptedAndLoading = patientIDs
    .some((id) => isEncrypted(id));
  if (someEncryptedAndLoading) return 'loading';
  const patients = patientIDs.map((id) => store.state.users.users[id]).filter(nullishFilter);
  return patients;
};

export const getWaypointsByItineraryID = (
  itineraryID: string,
): Waypoint[] => {
  const store = getStore();
  const itinerary = getItineraryByID(itineraryID);
  const waypoints = Object.values(
    itinerary?.waypoints ?? {},
  ).map(
    (waypointIDObj) => store.state.itineraries.waypoints?.[waypointIDObj.id],
  ).filter(nullishFilter);
  return waypoints;
};

export const getProceduresByItineraryID = (
  itineraryID: string,
): WithFriendlyStatus<Procedure>[] => {
  const store = getStore();
  const waypoints = getWaypointsByItineraryID(itineraryID);
  const waypointActions = waypoints.map(
    (waypoint) => waypoint.waypointActions?.map(
      (waypointActionObj) => store.state.itineraries.waypointActions?.[waypointActionObj.id],
    ).filter(nullishFilter),
  ).filter(nullishFilter).flat();
  const procedures = waypointActions.map(
    (waypointAction) => Object.values(waypointAction.procedures ?? {})?.map(
      (IDObj) => store.state.procedures.procedures[IDObj.id],
    ).filter(nullishFilter),
  ).flat();
  return procedures;
};

export const getPlaceGroupsByItineraryID = (
  itineraryID: string,
): PlaceGroup[] => {
  const store = getStore();
  const procedures = getProceduresByItineraryID(itineraryID);
  const placeGroups = procedures.map(
    (procedure) => {
      const placeGroupIDs = Object.values(procedure.dropOffPlaceGroups ?? {}).map((pg) => pg.id);
      const pgs = placeGroupIDs.map((id) => store.state.places.placeGroups?.[id]);
      return pgs;
    },
  ).flat().filter(nullishFilter);
  return deduplicateArray(placeGroups);
};

export const getTasksByItineraryID = (
  itineraryID: string,
): Task[] => {
  const store = getStore();
  const procedures = getProceduresByItineraryID(itineraryID);
  const tasks = procedures.map(
    (procedure) => store.getters[
      'variables/getTaskByID'
    ](procedure.taskID),
  ).filter(nullishFilter);
  return tasks;
};

const getWaypointLocationHash = (waypoint: Waypoint) => {
  if (!waypoint.location) return undefined;
  const latLng = { lat: waypoint.location.lat, lng: waypoint.location.lng };
  return generateHash(latLng);
};

export const getServiceRegionsByItineraryID = async (
  itineraryID: string,
): Promise<ServiceRegion[]> => {
  const ityStore = useItineraryStore();
  const store = getStore();
  const waypoints = getWaypointsByItineraryID(itineraryID);
  let serviceRegions: ByID<ServiceRegion> = {};
  (await Promise.all(waypoints.map(
    async (waypoint) => {
      const hash = getWaypointLocationHash(waypoint);

      const cached = hash ? ityStore.serviceRegionCache.get(hash) : undefined;
      if (cached) {
        serviceRegions = {
          ...serviceRegions,
          ...cached.serviceRegions,
        };
        return cached.serviceRegions;
      }
      const sr = await getServiceRegionFromLocation(
        waypoint.location,
        store.state.variables.variables,
        getValueOnce,
      );
      if (isNullish(sr) || isNullish(hash)) return;
      ityStore.serviceRegionCache.set({
        id: hash,
        serviceRegions: {
          [sr.id]: sr,
        },
      });
      return sr;
    },
  ))).filter(nullishFilter);
  ityStore.serviceRegionCache.set({
    id: itineraryID,
    serviceRegions,
  });
  return Object.values(serviceRegions);
};

export const getServiceRegionNamesByItineraryID = async (
  itineraryID: string,
): Promise<string[]> => {
  const serviceRegions = await getServiceRegionsByItineraryID(itineraryID);
  const serviceRegionNames = serviceRegions.map((sr) => sr.name);

  return deduplicateArray(serviceRegionNames);
};

export const getServiceRegionIDsByItineraryID = async (
  itineraryID: string,
): Promise<string[]> => {
  const serviceRegionNames = (await getServiceRegionsByItineraryID(itineraryID)).map((sr) => sr.id);

  return deduplicateArray(serviceRegionNames);
};

export const getPrescribersByItineraryID = (
  itineraryID: string,
): EncryptedUser[] => {
  const store = getStore();
  const waypoints = getWaypointsByItineraryID(itineraryID);
  const waypointActions = waypoints?.map(
    (waypoint) => waypoint?.waypointActions?.map(
      (idObj) => store.state.itineraries.waypointActions?.[idObj.id],
    ).filter(nullishFilter),
  ).flat().filter(nullishFilter);
  const procedures = waypointActions?.map(
    (waypointAction) => Object.values(waypointAction.procedures ?? {},
    ).map(
      (idObj) => store.state.procedures.procedures[idObj.id],
    ).filter(nullishFilter),
  ).flat();
  const orders = procedures?.map(
    (procedure) => store.state.orders.orders[procedure.orderID],
  ).filter(nullishFilter);
  const thePrescribers = orders?.map(
    ((order) => order.prescriberID ? store.state.users.users[order.prescriberID] : undefined),
  ).filter(nullishFilter) ?? [];
  return deduplicateArray(thePrescribers);
};

export const getItineraryRoute = (itineraryID?: string): RawLocation => ({
  name: routeNames.ITINERARY,
  params: { itineraryID: itineraryID ?? null },
});
