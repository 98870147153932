import { buildMaybePartnerRoute } from '@caresend/ui-components';

import { LocationData, routeNames } from '@/router/model';

export const databaseScriptsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.DATABASE_SCRIPTS });

export const filesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.FILES });

export const inventoryRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.INVENTORY });

export const locationsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.LOCATIONS });

export const loginRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.LOGIN });

export const nurseDispatchRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.NURSE_DISPATCH });

export const officesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFICES });

export const ordersRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ORDERS });

export const procedurePageRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PROCEDURE_PAGE });

export const processingOrdersRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PROCESSING_QUEUE });

export const sandboxRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SANDBOX });

export const rescheduleVisitRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.RESCHEDULE_VISIT });

export const shiftsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SHIFTS });

export const taskTypesListRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.TASK_TYPES_LIST });

export const tasksListRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.TASKS_LIST });

export const testsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.TESTS });

export const usersRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.USERS });

export const waypointSupplyListRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.WAYPOINT_SUPPLY_LIST });

export const recruitingMapRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.RECRUITING_MAP });
