import {
  Booking,
  Place,
  Procedure,
  Waypoint,
  WaypointAction,
  WaypointActionType,
  WithFriendlyStatus,
} from '@caresend/types';
import { RichSelectOption, SupplyWithPatient, getStore } from '@caresend/ui-components';
import {
  capitalize,
  deduplicateArray,
  formatFullName,
  isNullish,
  nullishFilter,
} from '@caresend/utils';

import { getProceduresByOrderID, getWaypointActionsByOrderID, getWaypointsByOrderID } from '@/functions/order';
import { IDOption } from '@/views/dashboard/NurseDispatch/components/CreateItinerary/components/InputModal/model';

/** OBJECTS */

export const getOrderByItineraryID = (
  itineraryID: string,
): IDOption[] => {
  const store = getStore();
  const procedures = store.getters[
    'itineraries/getProceduresOnItinerary'
  ](itineraryID);
  const orderID = Object.values(procedures)[0]?.orderID;
  if (isNullish(orderID)) return [];
  const order = store.state.orders.orders[orderID];
  return [{
    id: order?.id,
    label: order?.id,
  }];
};

export const getBookingLabel = (booking: Booking) => {
  const store = getStore();
  const patient = store.state.users.users[booking.patientID];
  return `Booking for ${patient?.info.firstName} ${patient?.info.lastName}`;
};

export const getBookingOptions = (itineraryID: string): IDOption[] => {
  const store = getStore();
  const procedures = store.getters[
    'itineraries/getProceduresOnItinerary'
  ](itineraryID);
  let bookingIDs = procedures.map(
    (procedure) => procedure.bookingID);
  bookingIDs = deduplicateArray(bookingIDs);
  const bookings = bookingIDs.map(
    (id) => store.state.bookings.bookings[id])
    .filter(nullishFilter);

  return bookings.map((booking) => ({
    id: booking.id,
    label: getBookingLabel(booking),
  }));
};

export const getBookingOptionsByOrderID = (orderID: string): IDOption[] => {
  const store = getStore();
  const order = store.state.orders.orders[orderID];
  const bookings = Object.values(order?.bookings ?? {}).map(
    (booking) => store.state.bookings.bookings[booking.id],
  ).filter(nullishFilter);

  return bookings.map((booking) => ({
    id: booking.id,
    label: getBookingLabel(booking),
  }));
};

/**
 * Example label: Blood draw for John Smith
 */
const getProcedureLabel = (procedure: Procedure): string => {
  const store = getStore();
  const productInfo = store.getters[
    'procedures/getProcedureProductInfo'
  ](procedure.id);
  const productName = capitalize(productInfo?.friendlyName ?? 'Unknown Product');
  const patient = store.getters[
    'procedures/getPatientOnProcedure'
  ](procedure);

  return `${productName} for ${formatFullName(patient)}`;
};

export const getProcedureOptions = (itineraryID: string): IDOption[] => {
  const store = getStore();
  const procedures = store.getters[
    'itineraries/getProceduresOnItinerary'
  ](itineraryID);

  return procedures.map((procedure) => ({
    id: procedure.id,
    label: getProcedureLabel(procedure),
  }));
};

export const getProcedureOptionsByOrderID = (orderID: string): IDOption[] => {
  const procedures = getProceduresByOrderID(orderID);
  return procedures.map((procedure) => ({
    id: procedure.id,
    label: getProcedureLabel(procedure),
  }));
};

/**
 * Example labels:
 * - PickDrop, 123 Somewhere St., Los Angeles, CA
 * - Patient, 123 Somewhere St., Los Angeles, CA
 */
const getWaypointLabel = (waypoint: Waypoint): string => {
  const type = capitalize(waypoint.type);
  const address = waypoint.location.formattedAddress;
  return `${type}, ${address}`;
};

export const getWaypointOptions = (itineraryID: string): IDOption[] => {
  const store = getStore();
  const waypoints = store.getters[
    'itineraries/getWaypointsOnItinerary'
  ](itineraryID);

  return waypoints.map((waypoint) => ({
    id: waypoint.id,
    label: getWaypointLabel(waypoint),
  }));
};

export const getWaypointOptionsByOrderID = (orderID: string): IDOption[] => {
  const store = getStore();
  const waypointActions = getWaypointActionsByOrderID(orderID);
  const waypoints = waypointActions.map(
    (waypointAction) => waypointAction.waypointID
      ? store.state.itineraries.waypoints?.[waypointAction.waypointID]
      : undefined,
  ).filter(nullishFilter);

  return waypoints.map((waypoint) => ({
    id: waypoint.id,
    label: getWaypointLabel(waypoint),
  }));
};

/**
 * Example labels:
 * - John Smith
 * - Blood draw for John Smith, 123 Lab Address St, Los Angeles, CA
 * - Blood draw for John Smithereens + 2 other procedures, 123 Pickup Point St, Los Angeles, CA
 */
const getWaypointActionLabel = (waypointAction: WaypointAction): string => {
  const store = getStore();
  const firstProcedureID = Object.keys(waypointAction.procedures ?? {})[0];
  if (!firstProcedureID) return 'Missing procedure on waypoint action.';

  const patient = store.getters[
    'procedures/getPatientOnProcedure'
  ](firstProcedureID);
  const patientName = formatFullName(patient);

  if (waypointAction.type === WaypointActionType.PATIENT_ACTION) {
    return patientName;
  }

  const actionTypePrefix = `${capitalize(waypointAction.type)} - `;

  const firstProcedure = store.state.procedures.procedures[firstProcedureID];
  if (!firstProcedure) return 'Missing procedure on waypoint action.';
  const procedureCount = Object.keys(waypointAction.procedures ?? {}).length;
  const procedureLabel = getProcedureLabel(firstProcedure);
  const procedureCountSuffix = procedureCount > 1
    ? ` + ${procedureCount - 1} other procedure${procedureCount > 2 ? 's' : ''}`
    : '';

  const waypoint = store.getters[
    'itineraries/getWaypointFromWaypointAction'
  ](waypointAction);
  const address = waypoint?.location.formattedAddress;

  return `${actionTypePrefix}${procedureLabel}${procedureCountSuffix}, ${address}`;
};

export const getWaypointActionOptions = (itineraryID: string): IDOption[] => {
  const store = getStore();
  const waypointActions = store.getters[
    'itineraries/getWaypointActionsOnItinerary'
  ](itineraryID);

  return waypointActions.map((waypointAction) => ({
    id: waypointAction.id,
    label: getWaypointActionLabel(waypointAction),
  }));
};

export const getWaypointActionOptionsByOrderID = (
  orderID: string,
): IDOption[] => {
  const waypointActions = getWaypointActionsByOrderID(orderID);

  return waypointActions.map((waypointAction) => ({
    id: waypointAction.id,
    label: getWaypointActionLabel(waypointAction),
  }));
};

/** SUBJECTS */

/**
 * Example labels:
 * - LabCorp, 123 Somewhere St., Los Angeles, CA
 * - CareSend Kit Box, 123 Somewhere St., Los Angeles, CA
 */
const getPlaceLabel = (place: Place): string => {
  const store = getStore();
  const placeGroup = store.getters['places/getPlaceGroupByPlace'](place);
  const placeGroupName = placeGroup
    ? placeGroup.name
    : 'Unknown Place Group';
  const address = place.location.formattedAddress;

  return `${placeGroupName}, ${address}`;
};

export const getPlaceOptions = (itineraryID: string): RichSelectOption[] => {
  const store = getStore();
  const places = store.getters[
    'itineraries/getPlacesOnItinerary'
  ](itineraryID);

  return places.map((place) => ({
    id: place.id,
    label: getPlaceLabel(place),
  }));
};

export const getPlaceOptionsByOrderID = (orderID: string): RichSelectOption[] => {
  const store = getStore();
  const waypoints = getWaypointsByOrderID(orderID);
  const places = waypoints.map(
    (waypoint) => waypoint.itineraryID
      ? store.getters['itineraries/getPlacesOnItinerary'](waypoint.itineraryID)
      : undefined,
  )
    .filter(nullishFilter)
    .flat();

  return places.map((place) => ({
    id: place.id,
    label: getPlaceLabel(place),
  }));
};

/**
 * Example labels:
 * - Phlebotomy kit for John Smith
 * - Lavender tube for John Smith
 */
const getSupplyLabel = (supply: SupplyWithPatient): string => {
  const supplyName = supply.name;
  const patientName = formatFullName(supply.patient);

  return `${supplyName} for ${patientName}`;
};

/** Get options related to supplies and subsupplies */
export const getSupplyOptions = (
  itineraryID: string,
): RichSelectOption[] => {
  const store = getStore();
  const supplies = store.getters[
    'itineraries/getAllSuppliesOnItinerary'
  ](itineraryID);

  return supplies.map((supply) => ({
    id: supply.id,
    label: getSupplyLabel(supply),
  }));
};

export const getSupplyOptionsByOrderID = (
  orderID: string,
): RichSelectOption[] => {
  const store = getStore();
  const waypoints = getWaypointsByOrderID(orderID);
  const supplies = waypoints.map(
    (waypoint) => waypoint.itineraryID
      ? store.getters['itineraries/getAllSuppliesOnItinerary'](waypoint.itineraryID)
      : undefined,
  )
    .filter(nullishFilter)
    .flat();

  return supplies.map((supply) => ({
    id: supply.id,
    label: getSupplyLabel(supply),
  }));
};

export const getTaskOptions = (itineraryID: string): IDOption[] => {
  const store = getStore();
  const procedures = store.getters[
    'itineraries/getProceduresOnItinerary'
  ](itineraryID);
  const tasks = procedures.map(
    (procedure) => store.state.variables.variables?.tasks[procedure.taskID],
  );
  return tasks.map((task) => ({
    id: task?.id,
    label: task?.info.friendlyName,
  }));
};

export const getTaskOptionsByOrderID = (orderID: string): IDOption[] => {
  const store = getStore();
  const procedures = getProceduresByOrderID(orderID);
  const tasks = procedures.map(
    (procedure) => store.state.variables.variables?.tasks[procedure.taskID],
  );
  return tasks.map((task) => ({
    id: task?.id,
    label: task?.info.friendlyName,
  }));
};

const productOptions = (
  procedures: WithFriendlyStatus<Procedure>[],
): IDOption[] => {
  const store = getStore();
  const productIDs = procedures.map(
    (procedure) => Object.keys(procedure.products ?? []),
  ).flat();
  const products = store.getters['variables/getProductsByIDs'](
    productIDs,
  ).filter(nullishFilter);
  return products.map((product) => {
    let label = product.name;
    if (product.info?.name) {
      label = product.info?.name;
    }
    if (product.info?.friendlyName) {
      label = product.info?.friendlyName;
    }
    if (isNullish(label)) return;
    return {
      id: product.id,
      label,
    };
  }).filter(nullishFilter);
};

export const getProductOptions = (itineraryID: string): IDOption[] => {
  const store = getStore();
  const procedures = store.getters[
    'itineraries/getProceduresOnItinerary'
  ](itineraryID);
  return productOptions(procedures);
};

export const getProductOptionsByOrderID = (orderID: string): IDOption[] => {
  const procedures = getProceduresByOrderID(orderID);
  return productOptions(procedures);
};

export const getItineraryOptions = (orderID: string): IDOption[] => {
  const store = getStore();
  const procedures = getProceduresByOrderID(orderID);
  const waypointActions = procedures.map(
    (procedure) => Object.values(procedure.waypointActions ?? {},
    ).map(
      (waypointAction) => store.state.itineraries.waypointActions?.[waypointAction.id])
      .filter(nullishFilter),
  ).flat();
  const itineraries = waypointActions.map(
    (waypointAction) => waypointAction.itineraryID
      ? store.state.itineraries.itineraries?.[waypointAction.itineraryID]
      : undefined,
  ).filter(nullishFilter);
  return itineraries.map((itinerary) => ({
    id: itinerary.id,
    label: itinerary.id,
  }));
};
