<template>
  <div
    ref="container"
    class="SessionManager"
    :class="{ 'is-expanded': !isEmpty }"
  >
    <span
      v-for="(context, index) in contexts"
      :key="`${index}-${context?.contextID}`"
    >
      <div
        v-if="context"
        class="is-flex layout-column"
      >
        <div class="SessionManager__context-title mb-3">
          {{ context.contextID }}
        </div>
        <span
          v-for="(sessionInfo, sessionID) in context.computed.sessionInfo"
          :key="`${context.contextID}-${sessionID}`"
          class="SessionManager__session-container"
        >
          <div
            v-if="sessionInfo"
            class="SessionManager__session"
            :class="getSessionClasses(sessionInfo.id)"
            @click="!loadingSession && openSession(sessionInfo.id)"
          >
            <TransitionExpand horizontal>
              <div
                v-if="sessionInfo.date"
                class="SessionManager__session-date-container"
              >
                <span
                  v-for="(dateElement, key) in getDateElements(sessionInfo)"
                  :key="key"
                >
                  <span
                    v-if="dateElement"
                    class="SessionManager__session-date"
                    :class="`SessionManager__session-date--${key}`"
                  >
                    {{ dateElement }}
                  </span>
                </span>
              </div>
            </TransitionExpand>
            <div
              v-if="isSessionInfoLoading(sessionInfo.id)"
            >
              <Loading
                standalone
                active
              />
            </div>
            <div
              v-if="sessionInfo.icon"
              class="SessionManager__session-icon"
            >
              <FontAwesomeIcon
                :icon="sessionInfo.icon"
                size="16px"
              />
            </div>
            <div
              v-else
              class="SessionManager__session-icon"
            >
              <UserPhoto
                :user="getUserFromSessionInfo(sessionInfo)"
                :size="45"
                componentStyle="dark-grey"
                disablePhotoLink
              />
            </div>
            <div class="SessionManager__session-info">
              <div class="SessionManager__session-title">
                {{ sessionInfo.title }}
              </div>
              <div class="SessionManager__session-subtitle">
                {{ sessionInfo.subtitle }}
              </div>
            </div>
            <div class="SessionManager__delete-button-container">
              <ButtonComponent
                buttonSize="sm"
                buttonStyle="white"
                iconLeft="times"
                class="SessionManager__delete-button"
                @click="(e) => deleteSession(sessionInfo.id, e)"
              />
            </div>
          </div>
        </span>
      </div>
    </span>
  </div>
</template>

<script lang="ts">
import { EncryptedUser } from '@caresend/types';
import {
  ButtonComponent,
  Loading,
  TransitionExpand,
  UserPhoto,
  getStore,
  preventParentClickAndDefault,
} from '@caresend/ui-components';
import { isNullish } from '@caresend/utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import dayjs from 'dayjs';
import { computed, defineComponent, onMounted } from 'vue';

import { SessionContextID, SessionInfo, useSessionStore } from '@/store/modules/itinerary/sessionStore';

export default defineComponent({
  name: 'SessionManager',
  components: {
    ButtonComponent,
    FontAwesomeIcon,
    Loading,
    TransitionExpand,
    UserPhoto,
  },
  setup() {
    const sessionStore = useSessionStore();
    const store = getStore();

    onMounted(sessionStore.initSessions);

    const contextIDs = computed(() => Object.values(SessionContextID));

    const isSessionActive = (sessionID: string) => sessionID === sessionStore.activeSession;
    const loadingSession = computed<boolean>(() => sessionStore.sessionLoading);

    const getInfoBySessionID = (sessionID: string): SessionInfo | null =>
      sessionStore.sessionInfoByID.getRaw(sessionID);

    const isSessionInfoLoading = (sessionID: string) => !getInfoBySessionID(sessionID) && loadingSession.value;

    const contexts = computed(() => contextIDs.value.map((id) => sessionStore.sessionsByContext.get(id)));

    const isEmpty = computed(() => !contexts.value.some((context) => (context?.computed.sessionInfo?.length ?? 0) > 0));

    const getSessionClasses = (sessionID: string) => {
      const classes = [];
      if (isSessionActive(sessionID)) {
        classes.push('SessionManager__session--active');
      }
      if (loadingSession.value) {
        classes.push('SessionManager__session--loading');
      }
      return classes;
    };

    const getUserFromSessionInfo = (
      sessionInfo: SessionInfo,
    ): EncryptedUser | { info: Partial<EncryptedUser['info']> } | undefined => {
      if (!sessionInfo.userID) return;
      return store.state.users.users[sessionInfo.userID] ?? { info: { picture: sessionInfo.img } };
    };

    const getDateElements = (sessionInfo: SessionInfo) => {
      if (!sessionInfo.date || isNullish(sessionInfo.date.timestamp)) return {};
      const date = dayjs(sessionInfo.date.timestamp).tz(sessionInfo.date.timeZone);
      let time = '';
      if (sessionInfo.time && !isNullish(sessionInfo.time.timestamp)) {
        time = dayjs(sessionInfo.time.timestamp).tz(sessionInfo.time.timeZone).format('hA');
      }
      return {
        month: date.format('MMM'),
        day: date.format('D'),
        bottom: time ?? date.format('YYYY'),
      };
    };

    const currentYear = computed(() => dayjs().format('YYYY'));

    const openSession = (sessionID: string) => {
      if (isSessionActive(sessionID)) return;
      sessionStore.openItinerarySession({
        itineraryID: sessionID,
      });
    };

    const deleteSession = (sessionID: string, e: MouseEvent) => {
      preventParentClickAndDefault(e);
      sessionStore.deleteItinerarySession(sessionID);
    };

    return {
      contexts,
      currentYear,
      deleteSession,
      getDateElements,
      getInfoBySessionID,
      getSessionClasses,
      getUserFromSessionInfo,
      isEmpty,
      isSessionInfoLoading,
      isSessionActive,
      loadingSession,
      openSession,
    };
  },
});
</script>

<style lang="sass" scoped>
=no-wrap
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.SessionManager
  flex-grow: 1
  width: 0
  min-width: 0
  padding: 0
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out, min-width 0.3s ease-in-out
  &:hover
    min-width: 200px
    ::v-deep .SessionManager__session-info
      width: 160px
  &.is-expanded
    min-width: fit-content
    width: fit-content
    padding: 20px 24px

.SessionManager
  height: 100%

.SessionManager__title
  font-size: 14px
  text-transform: uppercase
  font-weight: 600
  margin-bottom: 20px
  opacity: 0.6

.SessionManager__session
  position: relative
  display: flex
  flex-direction: row
  align-items: center
  justify-content: flex-start
  transition: background-color 0.1s ease-in-out, opacity 0.2s ease-in-out
  background-color: transparentize($cs-grey-900, 0.9)
  gap: 10px
  padding: 6px 3px 6px 10px
  border-radius: 12px
  border: 2px solid transparent
  +cs-custom-box-shadow(12px, 4px, rgba(0, 0, 0, 0.05))
  &:hover:not(.SessionManager__session--loading)
    cursor: pointer
    background-color: transparentize($cs-grey-900, 0.84)
    ::v-deep .SessionManager__delete-button-container
      opacity: 1
  &--loading
    cursor: wait
    opacity: 0.5
    ::v-deep .SessionManager__delete-button-container
      opacity: 0
  &--active
    +cs-custom-box-shadow(12px, 4px, rgba(0, 0, 0, 0.08))
    background-color: transparentize($cs-primary, 0.95)
    border: 2px solid $cs-primary
    ::v-deep .SessionManager__session-date--month
      background-color: $cs-primary
    &:hover:not(.SessionManager__session--loading)
      background-color: transparentize($cs-primary, 0.8)

.SessionManager__session-container
  &+&
    margin-top: 12px

.SessionManager__delete-button-container
  position: absolute
  display: flex
  align-items: flex-start
  justify-content: center
  right: 8px
  top: 4px
  bottom: 0
  width: 35px
  opacity: 0
  transition: opacity 0.2s ease-in-out

.SessionManager__delete-button
  opacity: 0.5
  background-color: transparentize($cs-grey-100, 0.5) !important
  border: 0
  padding: 4px 18px !important
  transition: opacity 0.2s ease-in-out
  &:hover
    opacity: 1

.SessionManager__session-icon
  display: flex
  align-items: center
  justify-content: center
  height: 100%

.SessionManager__session-info
  flex-grow: 1
  overflow: hidden
  width: 0px
  transition: width 0.1s ease-in-out
  +no-wrap

.SessionManager__session-title
  font-size: 13px
  font-weight: $cs-font-weight-bold
  text-transform: uppercase

.SessionManager__session-subtitle
  font-size: 11px
  opacity: 0.6

.SessionManager__session-date-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 0px
  line-height: 1.1

.SessionManager__session-date
  display: flex
  margin-left: -24px
  align-items: center
  justify-content: center
  font-size: 13px
  text-align: center
  text-transform: uppercase
  font-weight: $cs-font-weight-bold
  +cs-custom-box-shadow(8px, 4px, rgba(0, 0, 0, 0.06), 0, 0)
  +no-wrap
  &--month
    padding: 2px 0
    min-width: 44px
    font-size: 12px
    opacity: 1
    color: $cs-grey-200
    background-color: $cs-grey-600
    border-top-left-radius: 8px
    border-top-right-radius: 8px
  &--day
    min-width: 44px
    padding: 1.5px 0
    background-color: $white
    font-size: 19px
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    color: $cs-grey-700
  &--bottom
    margin-top: 4px
    background-color: $cs-grey-700
    color: $cs-grey-300
    padding: 2px 1px
    border-radius: 4px
    min-width: 36px
    font-size: 10px

.SessionManager__context-title-container
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  padding-top: 12px
  height: 50px
  width: 100%

.SessionManager__context-title
  font-size: 14px
  text-transform: uppercase
  font-weight: 600
  margin-bottom: 20px
  opacity: 0.6
</style>
