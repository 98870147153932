/* eslint-disable no-underscore-dangle */
import {
  ByID,
  DbRef,
  DraftWaypoint,
  EncryptedUser,
  Itinerary,
  LocationObject,
  Place,
  Procedure,
  ProcedureDateInfo,
  ServiceRegion,
  Shipment,
  Waypoint,
  WaypointAction,
  WaypointActionStatusName,
  WaypointActionType,
  WaypointStatusName,
  WaypointType,
} from '@caresend/types';
import { generateHash, getStore } from '@caresend/ui-components';
import {
  arrayToObj,
  checkStatus,
  formatCamelCase,
  formatFullName,
  generateID,
  getDraftWaypointPath,
  getItineraryPath,
  getLatLngDeviationInMiles,
  getProcedurePath,
  getShipmentPath,
  getWaypointActionPath,
  getWaypointPath,
  initWaypoint,
  isNullish,
  nullishFilter,
  objectFilter,
  objectMap,
  objectSome,
  timeToMilliseconds,
} from '@caresend/utils';
import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { initItinerary } from '@/data/itineraries/init';
import { useDbSyncStore } from '@/store/modules/itinerary/dbSyncStore';
import {
  extractItineraryWithRootState,
  extractTarget,
  extractTargetOrPriority,
  generateWaypointActions,
  itineraryRecordWithRootState,
} from '@/store/modules/itinerary/helpers';
import {
  ItinerarySetters,
  PlaceOption,
  ServiceRegionCache,
  TargetOrID,
  WaypointPreview,
} from '@/store/modules/itinerary/model';
import type { SessionInfo } from '@/store/modules/itinerary/sessionStore';
import { InjectedNulls, injectNulls } from '@/store/modules/itinerary/utils/injectNulls';
import { recordManager } from '@/store/utils/recordManager/recordManager';
import { InputType } from '@/views/dashboard/NurseDispatch/components/CreateItinerary/components/InputModal/controller';

const initialItinerary = injectNulls(initItinerary());

export const useItineraryStore = defineStore('itinerary', () => {
  const store = getStore();

  const itinerary = reactive<Itinerary>(initialItinerary);

  /**
   * Reserved for shallow minimal state values
   */
  const state = {
    set: {
      isReady: (val: boolean) => {
        state.value.isReady = val;
      },
      inputModalOpened: (val: boolean) => {
        state.value.inputModalOpened = val;
      },
      modalInputType: (val: InputType) => {
        state.value.modalInputType = val;
      },
    },
    value: reactive({
      isReady: false,
      inputModalOpened: false,
      modalInputType: InputType.NOTE,
    }),
  };

  /**
   * All records managed by the itinerary store
   * Maintained as an object to support self referencing computation functions
   * `_` appended to ensure the housing for the managers are retained privately
   */
  const _r = {
    procedures: recordManager<Procedure>({
      label: 'procedure',
      pathGetter: getProcedurePath,
      context: DbRef.ITINERARIES,
    })({}),

    serviceRegions: recordManager<ServiceRegion>()({}),

    serviceRegionCache: recordManager<ServiceRegionCache>({
      persist: true,
      context: 'serviceRegionCache',
    })({}),

    draftShipments: recordManager<Shipment>({
      label: 'shipment',
      pathGetter: getShipmentPath,
      context: DbRef.ITINERARIES,
    })({}),

    waypointActions: recordManager<WaypointAction>({
      label: 'waypoint action',
      pathGetter: getWaypointActionPath,
      context: DbRef.ITINERARIES,
      allowUnset: true,
    })({
      combinableMatch: (
        waypointAction0: TargetOrID<WaypointAction>,
        waypointAction1: TargetOrID<WaypointAction>,
      ): boolean => {
        const wpa0 = extractTarget(waypointAction0, _r.waypointActions.getRaw);
        const wpa1 = extractTarget(waypointAction1, _r.waypointActions.getRaw);
        if (!wpa0 || !wpa1) return false;

        /**
         * Check if the waypoint actions are the same except for id and status
         */
        const wpa0Copy: Partial<WaypointAction> = cloneDeep(wpa0);
        const wpa1Copy: Partial<WaypointAction> = cloneDeep(wpa1);
        delete wpa0Copy.id;
        delete wpa0Copy.status;
        delete wpa1Copy.id;
        delete wpa1Copy.status;
        const isDuplicate = isEqual(wpa0Copy, wpa1Copy);

        /**
         * Check if the waypoint actions are the same patient
         */
        const singlePatientID0 = _r.waypointActions.getters.singlePatientID(wpa0.id);
        const singlePatientID1 = _r.waypointActions.getters.singlePatientID(wpa1.id);
        const isSamePatient = singlePatientID0 === singlePatientID1;

        /**
         * Check if the waypoint actions are the same type
         */
        const isSameType = wpa0.type === wpa1.type;

        return isDuplicate || (isSamePatient && isSameType);
      },

      /** Duration of a waypoint action rounded to 0.01 minutes */
      duration: (waypointAction: TargetOrID<WaypointAction>): number => {
        const wpa = extractTarget(waypointAction, _r.waypointActions.getRaw);
        return Math.round((wpa?.duration.actionDuration ?? 0) * 100) / 100;
      },

      procedures: (waypointAction: TargetOrID<WaypointAction>): ByID<Procedure> => objectFilter(
        objectMap(
          extractTarget(waypointAction, _r.waypointActions.getRaw)?.procedures ?? {},
          (_, id) => _r.procedures.getRaw(id) ?? store.getters['procedures/getProcedureByID'](id),
        ), nullishFilter,
      ),

      patientIDs: (waypointAction: TargetOrID<WaypointAction>): ByID<boolean> => {
        const patientIDs: ByID<boolean> = {};
        const wpa = extractTarget(waypointAction, _r.waypointActions.getRaw);
        if (!wpa) return patientIDs;
        objectMap(_r.waypointActions.getters.procedures(wpa.id) ?? {}, (procedure) => {
          const booking = store.getters['procedures/getProcedureBooking'](procedure.id);
          if (!booking) return;
          patientIDs[booking.patientID] = true;
        });
        return patientIDs;
      },

      /** If there is only one patient ID, return it, otherwise return undefined */
      singlePatientID: (waypointAction: TargetOrID<WaypointAction>): string | undefined => {
        const wpa = extractTarget(waypointAction, _r.waypointActions.getRaw);
        if (!wpa) return;
        const patientIDs = _r.waypointActions.getters.patientIDs(wpa.id);
        return Object.keys(patientIDs).length === 1 ? Object.keys(patientIDs)[0] : undefined;
      },
    }),

    waypoints: recordManager<Waypoint>({
      label: 'waypoint',
      pathGetter: getWaypointPath,
      context: DbRef.ITINERARIES,
      allowUnset: true,
    })({
      dateTimeRequest: (waypoint: TargetOrID<Waypoint>): ProcedureDateInfo[] | undefined => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        if (!wp) return;

        const wpProcedures = _r.waypoints.getters.procedures(wp.id);
        /** if some value does not match, return undefined */
        const dateInfo = Object.values(wpProcedures)
          .map((procedure) => procedure.dateInfo)
          .filter(nullishFilter);
        if (dateInfo.length === 0) return undefined;

        const dateTimeRequest = dateInfo[0];
        return dateInfo.every((info) => isEqual(info, dateTimeRequest))
          ? dateTimeRequest
          : undefined;
      },

      draftWaypointMismatched: (_waypoint: TargetOrID<Waypoint>): boolean => {
        const itineraryID = itinerary.id;
        const waypoint = extractTarget(_waypoint, _r.waypoints.getRaw);
        if (!waypoint) return false;

        const getDraftWaypointMismatch = (draftWaypoint: DraftWaypoint): boolean =>
          !isNullish(draftWaypoint.itineraryID)
        && draftWaypoint.itineraryID !== itineraryID;
        const getDraftWaypoints = (wp: Waypoint) => Object.keys(wp.draftWaypoints ?? {})
          .map((id) => store.state.draftWaypoints.draftWaypoints?.[id])
          .filter(nullishFilter);
        return getDraftWaypoints(waypoint).some(getDraftWaypointMismatch);
      },

      nurseDepartedLate: (waypoint: TargetOrID<Waypoint>): boolean => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        if (!wp?.transitInfo?.completionTime?.timestamp) return false;

        const waypointDuration = _r.waypoints.getters.totalDuration(wp.id);
        const expectedDepatureTime = dayjs(wp.date?.timestamp).add(waypointDuration, 'm');
        const actualDepartureTime = dayjs(wp.transitInfo.completionTime.timestamp);
        return actualDepartureTime.isAfter(expectedDepatureTime);
      },

      /** Total duration of a waypoint's actions */
      totalDuration: (waypoint: TargetOrID<Waypoint>): number => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        if (!wp) return 0;
        return (_r.waypoints.getters.waypointActions(wp.id) ?? [])
          .reduce((acc, curr) => {
            const duration = curr?.duration?.actionDuration ?? 0;
            return acc + duration;
          }, 0);
      },

      /**
       * Get service regions from the cache for a waypoint
       * If the service regions are not in the cache
       */
      serviceRegionsFromCache: (waypoint: TargetOrID<Waypoint>): ByID<ServiceRegion> => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        if (!wp) return {};
        const latLng = { lat: wp.location.lat, lng: wp.location.lng };
        const hash = generateHash(latLng);
        return _r.serviceRegionCache.get(hash)?.serviceRegions ?? {};
      },

      waypointActions: (waypoint: TargetOrID<Waypoint>): WaypointAction[] => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        const wpas = wp?.waypointActions?.map(
          (waypointAction) => _r.waypointActions.getRaw(waypointAction.id),
        ).filter(nullishFilter) ?? [];
        return wpas;
      },

      procedures: (waypoint: TargetOrID<Waypoint>): ByID<Procedure> => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        return wp?.waypointActions?.map(
          ({ id }) => _r.waypointActions.getters.procedures(id),
        ).reduce((acc, procedureIDs) => ({
          ...acc,
          ...procedureIDs,
        }), {}) ?? {};
      },

      timeZone: (waypoint: TargetOrID<Waypoint>): string | undefined => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        return wp?.location.timeZone;
      },
    }, {
      deviationInMiles: (waypoint: TargetOrID<Waypoint>, loc: LocationObject | undefined): number => {
        const wp = extractTarget(waypoint, _r.waypoints.getRaw);
        if (!wp || !loc) return 0;
        const { location } = wp;
        return getLatLngDeviationInMiles(location, loc);
      },

      /**
       * Determines if two waypoints match to a certain degree
       * i.e. same location, same type, and same place
       */
      combinableMatch: (
        _wp0: TargetOrID<Waypoint>,
        _wp1: TargetOrID<Waypoint>,
      ): boolean => {
        const wp0 = extractTarget(_wp0, _r.waypoints.getRaw);
        const wp1 = extractTarget(_wp1, _r.waypoints.getRaw);
        if (!wp0 || !wp1) return false;
        return wp0.location.lat === wp1.location.lat
          && wp0.location.lng === wp1.location.lng
          && wp0.type === wp1.type
          && (
            !wp1.placeID
            || wp0.placeID === wp1.placeID
          );
      },
    }),

    draftWaypoints: recordManager<DraftWaypoint>({
      label: 'draft waypoint',
      pathGetter: getDraftWaypointPath,
      context: DbRef.ITINERARIES,
    })({
      existingPreview: (
        draftWaypoint: TargetOrID<DraftWaypoint>,
      ): WaypointPreview | undefined => {
        const dw = extractTarget(draftWaypoint, _r.draftWaypoints.getRaw);
        if (!dw?.waypointID) return;

        const previewWaypoints = _r.waypoints.filterRaw(
          (waypoint) => !!waypoint?.draftWaypoints?.[dw.id],
        );

        const waypoint = Object.values(previewWaypoints ?? {})[0];
        if (!waypoint) return;

        const combinableWaypointIDs = _r.itinerary.computed.combinableWaypointIDs(waypoint);

        const waypointActions = _r.waypoints.getters.waypointActions(waypoint.id);
        return {
          combinableWaypointIDs,
          waypoint,
          waypointActions,
          draftWaypointID: dw.id,
        };
      },
    }, {
      preview: (draftWaypoint: TargetOrID<DraftWaypoint>): WaypointPreview => {
        const dw = extractTarget(draftWaypoint, _r.draftWaypoints.getRaw);
        const getDefault = (): WaypointPreview => ({
          draftWaypointID: dw?.id ?? '',
          ..._r.itinerary.computed.initWaypointWithSuggestions({
            draftWaypointID: dw?.id ?? '',
            waypointType: WaypointType.PATIENT,
          }),
        });

        if (!dw) return getDefault();

        return _r.draftWaypoints.getters.existingPreview(draftWaypoint) ?? getDefault();
      },
    }),

    itinerary: {
      /**
       * Setters for the itinerary.
       * Child setters are defined as an object generated by the keys of the
       * itinerary structure. This allows for interfacing with children of the
       * itinerary in a simple way.
       *
       * i.e itinerary.set.status(ItineraryStatusName.CREATED)
       *
       * To set the itinerary as a whole, use the `value` setter.
       * i.e itinerary.set.value(itinerary)
       */
      set: {
        /** Enables itinerary.set.{key}(val) */
        ...Object.keys(injectNulls(initItinerary())).reduce((acc, _key) => {
          const key = _key as keyof Itinerary;
          acc[key] = (value: Itinerary[typeof key], queueUpdate = false) => {
            itinerary[key] = (value ?? null) as never;

            if (!queueUpdate) return;
            const label = typeof queueUpdate === 'string'
              ? queueUpdate
              : `set itinerary ${formatCamelCase(key).toLowerCase()}`;
            useDbSyncStore().queueNextUpdate(
              getItineraryPath(itinerary.id, key),
              label,
              value,
              DbRef.ITINERARIES,
            );
          };
          return acc;
        }, {} as ItinerarySetters),
        value: (itineraryID: Itinerary) => {
          Object.assign(itinerary, itineraryID);
        },
      } as ItinerarySetters,
      value: itinerary,
      computed: {
        assignedNurse: (
          itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>,
        ): EncryptedUser | undefined => {
          const i = extractTargetOrPriority(itineraryID, extractItineraryWithRootState, itinerary);
          if (!i.nurseID) return;
          return store.state.users.users?.[i.nurseID];
        },

        combinableWaypointIDs: (
          waypoint: TargetOrID<Waypoint>,
          itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>,
        ): string[] => {
          const wp = extractTarget(waypoint, _r.waypoints.getRaw);
          if (!wp) return [];
          return _r.itinerary.computed.currentWaypoints(itineraryID)
            .filter((wp0) => _r.waypoints.getters.combinableMatch(wp0, waypoint))
            .map((wp0) => wp0.id);
        },

        currentWaypoints: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): Waypoint[] => {
          const i = extractTargetOrPriority(itineraryID, extractItineraryWithRootState, itinerary);
          return i.waypoints
            .map(({ id }) => _r.waypoints.getRaw(id))
            .filter(nullishFilter) ?? [];
        },

        currentWaypointActions: (
          itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>,
        ): ByID<WaypointAction> =>
          _r.itinerary.computed.currentWaypoints(itineraryID)
            .reduce((acc, waypoint) => ({
              ...acc,
              ...arrayToObj(_r.waypoints.getters.waypointActions(waypoint.id), 'id'),
            }), {} as ByID<WaypointAction>),

        draftWaypointIDs: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): ByID<boolean> =>
          _r.itinerary.computed.currentWaypoints(itineraryID).reduce((acc, waypoint) => ({
            ...acc,
            ...waypoint.draftWaypoints,
          }), {} as ByID<boolean>),

        itinerarySession: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): SessionInfo | undefined => {
          const i = extractTargetOrPriority(itineraryID, extractItineraryWithRootState, itinerary);

          const nurse = _r.itinerary.computed.assignedNurse(itineraryID);
          const name = formatFullName(nurse, false, false);
          const title = nurse
            ? `${name}`
            : 'Unassigned';
          const serviceRegions = _r.itinerary.computed.serviceRegionsFromCache(itineraryID);
          const serviceRegionNames = Object.values(serviceRegions).map((sr) => sr.name).join(', ');
          return {
            title,
            subtitle: `${serviceRegionNames}`,
            date: i.dateInfo.date,
            time: i.startTime ?? undefined,
            userID: i.nurseID ?? undefined,
            img: nurse?.info.picture,
            statusDbRef: getItineraryPath(i.id, 'status'),
            sessionID: i.id,
            id: i.id,
          };
        },

        someDraftWaypointMismatched: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): boolean =>
          _r.itinerary.computed.currentWaypoints(itineraryID).some((wp) =>
            _r.waypoints.getters.draftWaypointMismatched(wp),
          ),

        /** Used to identify the need to commit additions */
        someDraftChildExists: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): boolean =>
          _r.itinerary.computed.currentWaypoints(itineraryID).some((wp) =>
            checkStatus(wp.status, WaypointStatusName.DRAFT)
              || _r.waypoints.getters.waypointActions(wp).some((wpa) =>
                checkStatus(wpa.status, WaypointActionStatusName.DRAFT))),

        placeOptions: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): PlaceOption[] => {
          const options: PlaceOption[] = [];
          const relatedProcedures = _r.itinerary.computed.procedures(itineraryID);
          Object.values(relatedProcedures).forEach((procedure: Procedure) => {
            const placeAccountRefs = procedure.placeAccounts;
            const placeAccountIDs = Object.keys(placeAccountRefs ?? {});
            placeAccountIDs.forEach((id: string) => {
              const placeAccount = store.state.places.placeAccounts?.[id];
              if (!placeAccount?.privatePlaces) return;
              placeAccount?.privatePlaces.forEach((place: Place) => {
                options.push({
                  place,
                  placeAccount,
                });
              });
            });
          });
          return options;
        },

        /**
         * Determine if a given procedure in an itinerary has a drop-off or mail-in
         * waypoint action but has no correlated patient action
         */
        procedureIsDropOffOrphaned: (
          procedureID: TargetOrID<Procedure>,
          itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>,
        ): boolean => {
          const procedure = extractTarget(procedureID, (id) => _r.procedures.get(id));
          if (!procedure) return false;

          const wpas = objectFilter(
            _r.itinerary.computed.currentWaypointActions(itineraryID),
            (wpa) => !!wpa.procedures?.[procedure.id],
          );
          const patientActionExists = objectSome(wpas, (waypointAction) =>
            waypointAction?.type === WaypointActionType.PATIENT_ACTION,
          );
          const mailInOrDropOffExists = objectSome(wpas, (waypointAction) =>
            waypointAction?.type === WaypointActionType.DROPOFF
            || waypointAction?.type === WaypointActionType.MAIL_IN,
          );

          return !patientActionExists && mailInOrDropOffExists;
        },

        procedures: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): ByID<Procedure> =>
          Object.values(_r.itinerary.computed.currentWaypointActions(itineraryID))
            .filter((wpa: WaypointAction) => wpa.type === WaypointActionType.PATIENT_ACTION)
            .reduce<ByID<Procedure>>((currentProcedures: ByID<Procedure>, wpa: WaypointAction) => ({
              ...currentProcedures,
              ..._r.waypointActions.getters.procedures(wpa.id),
            }), {} as ByID<Procedure>),

        serviceRegionsFromCache: (
          itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>,
        ): ByID<ServiceRegion> => {
          const i = extractTargetOrPriority(itineraryID, extractItineraryWithRootState, itinerary);
          return {
            ..._r.serviceRegionCache.get(i.id)?.serviceRegions,
            ..._r.itinerary.computed.currentWaypoints(itineraryID).reduce((acc, waypoint) => {
              const serviceRegion = _r.waypoints.getters.serviceRegionsFromCache(waypoint);
              if (!serviceRegion) return acc;
              return {
                ...acc,
                ...serviceRegion,
              };
            }, {} as ByID<ServiceRegion>),
          };
        },

        timeZone: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): string | undefined => {
          const waypointID = _r.itinerary.computed.currentWaypoints(itineraryID)[0]?.id;
          if (!waypointID) return;
          return _r.waypoints.getters.timeZone(waypointID)
            ?? itinerary.dateInfo.date.timeZone;
        },

        totalRoutesDuration: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): number =>
          (extractTarget(itineraryID, (id) => itineraryRecordWithRootState(itinerary)[id]) ?? itinerary)
            ?.routes?.reduce((total, { duration }) => total + duration, 0) ?? 0,

        totalDuration: (itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): number =>
          _r.itinerary.computed.totalRoutesDuration(itineraryID)
            + _r.itinerary.computed.currentWaypoints(itineraryID).reduce(
              (total, waypoint) => total + _r.waypoints.getters.totalDuration(waypoint), 0,
            ),

        waypointIndexByID: (
          waypointID: string,
          itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>,
        ): number =>
          _r.itinerary.computed.currentWaypoints(itineraryID).findIndex(
            (waypoint) => waypoint.id === waypointID,
          ),

        /** Get the index of a waypoint from the level of the waypoint action */
        wpaParentIndex: (wpaID: string, itineraryID?: TargetOrID<InjectedNulls<Itinerary> | Itinerary>): number =>
          _r.itinerary.computed.currentWaypoints(itineraryID).findIndex(
            (waypoint) => waypoint.waypointActions
              && waypoint.waypointActions.findIndex(({ id }) => id === wpaID) !== -1,
          ),

        initWaypointWithSuggestions: (payload: {
          location?: LocationObject;
          waypointType: WaypointType;
          place?: Place;
          draftWaypointID?: string;
        }): Omit<WaypointPreview, 'draftWaypointID'> => {
          let { location } = payload;
          const { draftWaypointID, waypointType, place } = payload;

          const waypointID = generateID();

          let wpProcedures: ByID<boolean>;
          let wpas: WaypointAction[] = [];

          const draftWaypoint = draftWaypointID
            ? _r.draftWaypoints.get(draftWaypointID)
            : undefined;

          if (draftWaypoint && !location && !place) {
            wpProcedures = draftWaypoint.procedures;
            location = draftWaypoint.location;
            wpas = generateWaypointActions(
              WaypointActionType.PATIENT_ACTION,
              Object.keys(wpProcedures ?? {}),
              waypointID,
              _r.itinerary.value.id,
            );
          }

          let dateTimestamp = draftWaypoint?.finalizedDate?.date.timestamp;
          const dateTimeZone = draftWaypoint?.dateRange?.startDate.timeZone
              ?? draftWaypoint?.finalizedDate?.date.timeZone ?? dayjs.tz.guess();
          if (draftWaypoint?.dateRange?.startDate.timestamp && draftWaypoint?.timeRange) {
            const time
            = (Object.values(draftWaypoint.timeRange).sort((a, b) => b.startTime - a.startTime)[0]?.startTime ?? 0);
            const timeToAddInMs = timeToMilliseconds(time);
            dateTimestamp = draftWaypoint.dateRange.startDate.timestamp + timeToAddInMs;
          }

          const waypoint: Waypoint = {
            ...initWaypoint(waypointType, location, place),
            date: {
              timeZone: dateTimeZone,
              timestamp: dateTimestamp ?? Date.now(),
            },
            id: waypointID,
            itineraryID: itinerary.id,
            status: {
              status: WaypointStatusName.DRAFT,
              timestamp: Date.now(),
            },
            waypointActions: wpas.map(({ id }) => ({ id })),
            ...(draftWaypointID && {
              draftWaypoints: { [draftWaypointID]: true },
            }),
          };

          const combinableWaypointIDs = _r.itinerary.computed.currentWaypoints()
            .filter((wp0) => _r.waypoints.getters.combinableMatch(wp0, waypoint))
            .map((wp) => wp.id);

          return { combinableWaypointIDs, waypoint, waypointActions: wpas };
        },
      },
    },
  };

  const init = (
    id?: string,
    userID?: string,
    queueUpdate = false,
  ) => {
    const newItinerary = injectNulls(initItinerary());
    if (id) newItinerary.id = id;
    if (userID) newItinerary.createdBy = userID;

    _r.itinerary.set.value(newItinerary);
    _r.waypoints.reset();
    _r.waypointActions.reset();
    _r.draftShipments.reset();
    _r.procedures.reset();
    _r.serviceRegions.reset();

    useDbSyncStore().provideContext(DbRef.ITINERARIES, newItinerary.id);

    if (queueUpdate) {
      useDbSyncStore().queueNextUpdate(
        getItineraryPath(newItinerary.id),
        'initialize new itinerary',
        newItinerary,
        DbRef.ITINERARIES,
      );
    }
  };

  const openInputModal = (inputType: InputType) => {
    state.set.modalInputType(inputType);
    state.set.inputModalOpened(true);
  };

  const closeInputModal = () => {
    state.set.inputModalOpened(false);
  };

  return {
    ..._r,
    closeInputModal,
    init,
    openInputModal,
    state,
  };
});
