/**
 * Session store to handle session management and route navigation for different contexts.
 * At its current stage, this is used to track open itineraries and navigate to them similar
 * to a tabbed interface.
 *
 * I can imagine this going in the direction of a tool to track the lifecycle of a set of orders
 * or management of live changes to any data type.
 *
 * Managing multiple sessions across different contexts is valuable to creating a better
 * understanding of memory and process usage.
 * @pumposh
 */
import { ByID, IdObj, TimeZoneDate } from '@caresend/types';
import { getRouter } from '@caresend/ui-components';
import { asyncTimeout, nullishFilter } from '@caresend/utils';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

import { useItineraryStore } from '.';
import { getServiceRegionIDsByItineraryID } from '@/functions/itinerary';
import { routeNames } from '@/router/model';
import { cleanInputWithInitializerDeep } from '@/store/modules/itinerary/helpers';
import { TargetOrID } from '@/store/modules/itinerary/model';
import { diffObjectsDeep } from '@/store/modules/itinerary/utils/diffDeep';
import { recordManager } from '@/store/utils/recordManager/recordManager';

export interface SessionInfo extends IdObj {
  date?: TimeZoneDate;
  icon?: string;
  img?: string;
  /** Status db path to bind for real time updates */
  statusDbRef: string;
  subtitle?: string;
  time?: TimeZoneDate;
  title: string;
  userID?: string;
  /** sessionID */
  sessionID: string;
}

type SessionInfoGetter = (id: string) => SessionInfo | undefined;

export interface SessionContext {
  /** Context ID */
  contextID: SessionContextID;
  /** Sessions in this context (i.e open itineraries) */
  sessions: Set<string>;
  /** Generated sorted */
  id: string;
}

type ContextGetterManager = {
  getter: SessionInfoGetter;
  /** Trigger to fetch dependencies for the context */
  dependencyTrigger: (id: string) => void | Promise<void>;
};

export enum SessionContextID {
  ITINERARY = 'Itineraries',
}

export const useSessionStore = defineStore('sessionStore', () => {
  const activeSession = ref<string | null>(null);
  const sessionLoading = ref<boolean>(false);
  const router = getRouter();

  const unwatchBySessionID: ByID<() => void> = {};

  const sessionInfoByID = recordManager<SessionInfo & IdObj>({
    context: 'sessionInfoByID',
    persist: true,
  })({});

  /** Watch changes to the session info */
  const initWatch = (sessionID: string, getter: SessionInfoGetter) => {
    unwatchBySessionID[sessionID] = watch(() => getter(sessionID), (newSessionInfo, oldSessionInfo) => {
      const currentSessionInfo = oldSessionInfo ?? sessionInfoByID.getRaw(sessionID);

      if (!newSessionInfo) return;

      const sessionInfo = currentSessionInfo
        ? cleanInputWithInitializerDeep<SessionInfo>(newSessionInfo, currentSessionInfo)
        : newSessionInfo;

      const diffs = diffObjectsDeep(currentSessionInfo, sessionInfo);
      if (!Object.keys(diffs).length) return;

      sessionInfoByID.set({
        ...sessionInfo,
        id: newSessionInfo.id,
      });
    }, { deep: true, immediate: true });
  };

  const sessionsByContext = recordManager<SessionContext>({
    context: 'sessionsByContext',
    persist: true,
  })({
    sessionInfo: (_context: TargetOrID<SessionContext>): SessionInfo[] => {
      let context: SessionContext | null;
      if (typeof _context === 'string') {
        context = sessionsByContext.getRaw(_context);
      } else {
        context = _context;
      }

      return [...(context?.sessions ?? [])]
        .map((sessionID) => sessionInfoByID.getRaw(sessionID))
        .filter(nullishFilter);
    },
  });

  const gettersByContextID: Record<SessionContextID, ContextGetterManager> = {
    [SessionContextID.ITINERARY]: {
      getter: useItineraryStore().itinerary.computed.itinerarySession,
      dependencyTrigger: (id) => {
        getServiceRegionIDsByItineraryID(id);
      },
    },
  };

  const initSessions = () => {
    sessionsByContext.resetComputed();

    /** Retrigger and watch for all info getters if possible */
    sessionsByContext.forEach((context) => {
      context.sessions.forEach((sessionID) => {
        gettersByContextID[context.contextID].dependencyTrigger(sessionID);
        initWatch(sessionID, gettersByContextID[context.contextID].getter);
      });
    });
  };

  const setActiveSession = (sessionID: string | null) => {
    activeSession.value = sessionID;
  };

  const openRoute = async (sessionID: string, noRoute: boolean, params: Record<string, any>) => {
    setActiveSession(sessionID);
    if (!noRoute) {
      sessionLoading.value = true;
      await asyncTimeout(100);
      router.push({ name: routeNames.ITINERARY, params });
    }
  };

  const openItinerarySession = ({
    itineraryID,
    noRoute = false,
  }: {
    itineraryID: string;
    /** If true, will not open the route */
    noRoute?: boolean;
  }) => {
    const existingContext = sessionsByContext.get(SessionContextID.ITINERARY);
    const existingSession = existingContext?.sessions?.has(itineraryID);

    const { getter } = gettersByContextID[SessionContextID.ITINERARY];

    if (getter) {
      unwatchBySessionID[itineraryID]?.();
      initWatch(itineraryID, getter);
    }

    if (existingSession) {
      openRoute(itineraryID, noRoute, {
        itineraryID,
      });
      return;
    }

    const sessions = existingContext?.sessions || new Set();
    sessions.add(itineraryID);

    const newContext: SessionContext = {
      ...existingContext,
      contextID: SessionContextID.ITINERARY,
      sessions,
      id: SessionContextID.ITINERARY,
    };
    sessionsByContext.set(newContext);

    openRoute(itineraryID, noRoute, {
      itineraryID,
    });
  };

  const deleteItinerarySession = async (sessionID: string) => {
    unwatchBySessionID[sessionID]?.();
    const context = sessionsByContext.get(SessionContextID.ITINERARY);
    if (!context) return;

    const { sessions } = context;
    sessions.delete(sessionID);
    sessionInfoByID.unset(sessionID);

    if (sessions.size === 0) {
      sessionsByContext.unset(SessionContextID.ITINERARY);
    } else {
      sessionsByContext.set({
        ...context,
        sessions,
      });
    }

    if (activeSession.value === sessionID) {
      setActiveSession(null);
      await asyncTimeout(300);
      router.push({ name: routeNames.SHIFTS });
    }
  };

  const closeActiveSession = () => {
    activeSession.value = null;
  };

  const setSessionLoaded = () => {
    sessionLoading.value = false;
  };

  const closeAllSessions = () => {
    sessionsByContext.forEach((context) => {
      context.sessions.forEach((sessionID) => {
        deleteItinerarySession(sessionID);
      });
    });
    sessionsByContext.reset();
    sessionInfoByID.reset();
  };

  return {
    activeSession,
    closeActiveSession,
    closeAllSessions,
    deleteItinerarySession,
    initSessions,
    openItinerarySession,
    sessionInfoByID,
    sessionLoading,
    sessionsByContext,
    setActiveSession,
    setSessionLoaded,
  };
});
