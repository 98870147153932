import { Itinerary, ItineraryStatus, Route } from '@caresend/types';
import { generateID } from '@caresend/utils';
import dayjs from 'dayjs';

const DEFAULT_ROUTE_DURATION = 30;

/**
 * Defined with optional fields populated with undefined to successfully
 * inject null values for itinerary setter configuration
 */
export const initItinerary = (): Itinerary => ({
  createdBy: '',
  dateInfo: {
    date: {
      timeZone: dayjs.tz?.guess(),
    },
  },
  baseNurseEarnings: 0,
  creationTimestamp: Date.now(),
  duration: 0,
  id: generateID(),
  leaveBy: undefined,
  nurseID: undefined,
  nursePaid: undefined,
  nursePaidTimestamp: undefined,
  routes: [],
  scheduledSetLeaveByEventID: undefined,
  shiftID: undefined,
  startTime: undefined,
  status: ItineraryStatus.DRAFT,
  supplyTransfers: undefined,
  waypoints: [],
});

export const initRoute = (to: string, from: string, duration?: number): Route => ({
  to: { id: to },
  from: { id: from },
  duration: duration ?? DEFAULT_ROUTE_DURATION,
});
